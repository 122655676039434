<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="h-100">
    <header-page>
      <span class="size14 fw-bold-700" style="color: #0B5FFF;margin-right: 5px;">Master</span>
      <span class="text-dark text-darken-5" style="margin-right: 5px;">></span>
      <span class="text-dark fw-bold-400 size14">Variation</span>
    </header-page>

    <div class="section">
      <div class="transaction__filter--container d-block bg-white py-2">
        <b-row class="search__export align-items-center">
          <b-col cols="10">
            <div class="d-flex pl-2">
              <div class="search__input w-100 pl-2 pr-0 mr-0">
                <b-input-group>
                  <b-form-input v-model="filter.search" placeholder="Cari nama satuan" />
                  <b-input-group-append is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
          </b-col>
          <b-col cols="2" class="px-0">
            <div>
              <b-button v-b-modal.form-modal class="py-1 border-8" @click="cleanUpForm()"
                v-if="checkPermission('add variation')">
                Tambah Variation
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <input v-model="filter.search" type="text" class="form-control" placeholder="Search...">
          </div>
        </div>
        <div class="col-md-2" />
        <div class="col-md-2">
          <button class="btn btn-primary btn-block" @click="exportData()">
            Export
          </button>
        </div>
        <div class="col-md-2">
          <button v-b-modal.import-modal class="btn btn-primary btn-block">
            Import
          </button>
        </div>
        <div class="col-md-2">
          <button v-b-modal.form-modal class="btn btn-primary btn-block" @click="cleanUpForm()">
            Create
          </button>
        </div>
      </div> -->

      <b-card-code no-body>
        <div class="table-responsive-sm">
          <table class="table b-table table-fixed">
            <thead>
              <tr>
                <th>Variation Type</th>
                <th>Variation Value</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading">
                <td colspan="3" class="text-center">
                  <b-spinner label="Loading..." variant="primary" />
                </td>
              </tr>
              <tr v-for="item in result.data" v-if="!isLoading" :key="item">
                <td>{{ item.type }}</td>
                <td>{{ item.name }}</td>
                <td class="text-right">
                  <div class="btn-group" role="group" aria-label="Basic example">

                    <!-- <button
                        class="btn btn-success btn-sm"
                        style="margin-left: 2px;"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Detail"
                        @click="detailItem(item)"
                      >
                        <feather-icon icon="EyeIcon" />
                      </button> -->

                    <!-- <b-button v-if="checkPermission('delete variation') " class="mr-1 px-1 border-0 bg-pink"
                      @click="deleteData(props.row.uuid)">
                      <feather-icon size="18" class="text-danger" icon="Trash2Icon" />
                    </b-button>
                    <b-button v-if="checkPermission('update variation') " class="bg-white" @click="editItem(props.row)">
                      <b-img :src="require('@/assets/images/icons/Process2.svg') " /> Ubah
                    </b-button> -->

                    <b-button v-if="checkPermission('update variation')" class="bg-white border-left"
                      style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Edit"
                      @click="editItem(item)">
                      <b-img :src="require('@/assets/images/icons/Process2.svg')" /> Ubah
                    </b-button>

                    <b-button v-if="checkPermission('delete variation')" class="mr-1 px-1 border-0 bg-pink"
                      style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Delete"
                      @click="deleteItem(item.uuid)">
                      <feather-icon size="18" class="text-danger" icon="Trash2Icon" />
                    </b-button>

                  </div>
                </td>
              </tr>
              <tr v-if="result.total == 0 && !isLoading">
                <td colspan="3" class="text-center">
                  Data is empty.
                </td>
              </tr>
            </tbody>
          </table>

          <div class="row mt-1">
            <div class="col-6 pl-2">
              <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
            </div>
            <div class="col-6 pr-2">
              <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
            </div>
          </div>
        </div>
      </b-card-code>

      <!-- Import Modal -->
      <b-modal id="import-modal" no-close-on-backdrop size="lg" title="Import" hide-footer>
        <div class="form">
          <div class="form-group">
            <label for="">File:</label><br>
            <input id="fileImport" ref="fileImport" type="file" @change="changeImportFile()">
          </div>

          <div v-if="isLoadingImport">
            <br>
            <b-spinner class="mb-2" variant="primary" /><br>
          </div>
          <button v-else class="btn btn-primary btn-sm" @click="importFile()">
            Import Data
          </button>
          <br>
          <hr>
          <small>Download import format <a href="/import-format/format-import-variation.xlsx">here</a></small>

        </div>
      </b-modal>
      <!-- Import Modal -->

      <!-- Add/Edit Modal -->
      <b-modal id="form-modal" size="md" :title="editId != null ? 'Edit' : 'Add'">

        <div class="form-group">
          <label for="">Variation Type:</label>
          <input v-model="formPayload.type" type="text" class="form-control"
            :class="validations.hasOwnProperty('type') && validations.type.length > 0 ? 'is-invalid' : ''"
            placeholder="Example: size, color, etc...">
          <small v-if="validations.hasOwnProperty('type') && validations.type.length > 0" class="text-danger">
            {{ validations.type.join(', ') }}
          </small>
        </div>

        <div class="form-group">
          <label for="">Variation Value:</label>
          <input v-model="formPayload.name" type="text" class="form-control"
            :class="validations.hasOwnProperty('name') && validations.name.length > 0 ? 'is-invalid' : ''"
            placeholder="Example: white, xl, l, etc...">
          <small v-if="editId == null">Split with comman (,) if you want to generate multiple value at once (Example:
            White,Yellow,Black)</small>
          <small v-if="validations.hasOwnProperty('name') && validations.name.length > 0" class="text-danger">
            {{ validations.name.join(', ') }}
          </small>
        </div>

        <template #modal-footer="{ }">
          <div v-if="isLoading">
            <br>
            <b-spinner class="mb-2" variant="primary" /><br>
          </div>
          <b-button v-if="editId == null" variant="success" :disabled="isLoading" @click="createItem()">
            Save Item
          </b-button>
          <b-button v-else variant="success" :disabled="isLoading" @click="updateItem()">
            Save Item
          </b-button>
        </template>
      </b-modal>
      <!-- Add/Edit Modal -->
    </div>

  </div>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner, BRow, BCol, BImg, BInputGroup, BFormInput, BInputGroupAppend
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import VueBarcode from 'vue-barcode'
import FileSaver from 'file-saver'
import axios from 'axios'
import vSelect from 'vue-select'

import $http from '@axios'

import HeaderPage from '@/components/HeaderPage.vue'

import 'vuejs-dialog/dist/vuejs-dialog.min.css'

Vue.use(VuejsDialog)

export default {
  title() {
    return 'Variation'
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner,
    VueBarcode,
    vSelect,
    HeaderPage,
    BRow, BCol, BImg, BInputGroup, BFormInput, BInputGroupAppend
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: Object,
      isLoading: false,
      isLoadingImport: false,
      formPayload: Object,
      detail: null,
      editId: null,
      filter: {
        search: '',
      },
      importPayload: {
        file: null,
      },
      formData: {},
      validations: {},
    }
  },
  created() {
    this.getData()
    this.loadFormData()
  },
  methods: {
    changeImportFile() {
      // eslint-disable-next-line prefer-destructuring
      this.importPayload.file = this.$refs.fileImport.files[0]
    },
    downloadImportFormat() {

    },
    importFile() {
      this.isLoadingImport = true

      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.importPayload) {
        if (this.importPayload.hasOwnProperty(key) && this.importPayload[key] != null) {
          form.append(key, this.importPayload[key])
        }
      }
      $http.post('/variation/import', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          successNotification(this, 'Success', 'Data successfully imported')
          this.isLoadingImport = false
          this.getData()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoadingImport = false
        })
    },
    detailItem(item) {
      this.detail = item

      this.$bvModal.show('detail-modal')
    },
    exportData(type = 'excel') {
      const context = this
      const axiosSource = axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel, msg: 'Loading...' }

      $http.get('/variation/export', {
        responseType: type == 'view' ? '' : 'blob',
        params: this.filter,
        cancelToken: axiosSource.token,
      }).then(response => {
        var timestamp = Math.floor(Date.now() / 1000)
        if (type == 'view') {
          context.contentHtml = response.data.data.html
        } else {
          var timestamp = Math.floor(Date.now() / 1000)
          if (type == 'excel') {
            var fileName = `export-${timestamp}.xlsx`
            FileSaver.saveAs(response.data, fileName)
          }

          if (type == 'pdf') {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            var fileName = `export-${timestamp}.pdf`
            FileSaver.saveAs(blob, fileName)
          }
        }

        context.isLoading = false
      }).catch(error => {
        context.isLoading = false

        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
      })
    },
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        name: '',
        description: '',
      }
    },
    loadFormData() { },
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = this.currentPage

      $http.get('/variation', {
        params: queryParams,
      })
        .then(response => {
          console.log(response.data.data, 'response');
          this.result = response.data.data
          this.isLoading = false
        }).catch((error) => {
          console.log(error, 'error');
          this.isLoading = false
        })
    },
    createItem() {
      const form = this.preparePayload()

      $http.post('/variation', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully created')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    updateItem() {
      const form = this.preparePayload()
      form.append('_method', 'PATCH')

      $http.post(`/variation/${this.editId}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Item successfully updated!')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    preparePayload() {
      const form = new FormData()

      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      return form
    },
    editItem(item) {
      this.editId = item.uuid

      this.formPayload = {
        name: item.name,
        type: item.type,
      }

      this.$bvModal.show('form-modal')
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          $http.delete(`/variation/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Item successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
